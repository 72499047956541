import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PageContext from '@contexts/PageContext';
import { formatPostData } from '@services/postService';
import { ErrorPage } from '@containers';
import { apiClient } from '@/http-client';

const Custom404 = ({ data }) => (
  <PageContext.Provider value={data}>
    <ErrorPage />
  </PageContext.Provider>
);
export const getStaticProps = async ({ locale }) => {
  let data = null;

  try {
    const { data: post } = await apiClient.get('/api/Posts/1001168');
    data = formatPostData(post);
  } catch (error) {
    data = {};
  }

  // Ensure DisplayOptions is not undefined
  data.DisplayOptions = data.DisplayOptions || [];

  const checkTheme = data.DisplayOptions.find((x) => x.Name == 'Header')?.DisplayOptions?.find(
    (item) => item?.DisplayOptionKey == 'DarkMode'
  )?.Enabled;

  // Set DarkMode to null if checkTheme is undefined
  data.DarkMode = checkTheme ? checkTheme : null;

  // Replace undefined values in DisplayOptions with null
  data.DisplayOptions = data.DisplayOptions.map(option => ({
    ...option,
    DisplayOptions: option.DisplayOptions?.map(item => ({
      ...item,
      Enabled: item?.Enabled !== undefined ? item.Enabled : null,
    })) || [],
  }));

  const translations = await serverSideTranslations(locale, ['common', 'footer']);

  return {
    props: {
      data,
      ...translations
    }
  };
};



export default Custom404;
